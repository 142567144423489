import Vue from 'vue'
import VueRouter from 'vue-router'
import { cacheGetUser } from '../tools/tool.js'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: "/login",
        meta: { loginShow: true, containerShow: false },
    },
    {
        path: '/login',
        name: 'LoginRouter',
        component: () => import('@/views/Login.vue'),
        meta: { loginShow: true, containerShow: false },
    },
    {
        path: '/home',
        name: 'HomeRouter',
        component: () => import('@/views/Home.vue'),
        meta: { containerShow: true },
    },
    {
        path: '/user',
        name: 'UserRouter',
        component: () => import('@/views/User.vue'),
        meta: { containerShow: true },
    },
    {
        path: '/yuyue',
        name: 'YuYueRouter',
        component: () => import('@/views/YuYue.vue'),
        meta: { containerShow: true },
    },
    {
        path: '/goods',
        name: 'GoodsRouter',
        component: () => import('@/views/GoodsList.vue'),
        meta: { containerShow: true },
    },
    {
        path: '/goods/add',
        name: 'GoodsAddRouter',
        component: () => import('@/views/GoodsAdd.vue'),
        meta: { containerShow: true },
    },
    {
        path: '/goods/info',
        name: 'GoodsInfoRouter',
        component: () => import('@/views/GoodsInfo.vue'),
        meta: { containerShow: true },
    },
    {
        path: '/order',
        name: 'OrderRouter',
        component: () => import('@/views/Order.vue'),
        meta: { containerShow: true },
    },
    {
        path: '/order/all',
        name: 'OrderRouter',
        component: () => import('@/views/AllOrder.vue'),
        meta: { containerShow: true },
    },
    {
        path: '/signout',
        name: 'SignOutRouter',
        component: () => import('@/views/SignOut.vue'),
        meta: { containerShow: true },
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next();
    } else {
        if (!cacheGetUser()) {
            next('/login');
        } else {
            next();
        }
    }
});

export default router
