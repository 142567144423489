<template>
    <div class="header_top">
        <div class="topLogo" style="font-size:26px; font-weight:600;color:#409eff; padding-left:50px"> 商城后台 </div>
        <div style="padding-right:50px">
            欢迎!
        </div>
    </div>
</template>
<script>
export default {
    name: 'TopView',
    data() {
        return {
            user: {},
        }
    },
    methods: {},
    created() {
    }
}

</script>

<style scoped>
.header_top {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>>

